/**
 * Farmalytics Main Library
 * Author: Brandon Corbin <brandon@icorbin.com>
 * Team: Jarvis
 * Date: Nov 8 2021
 */
import { API_ENDPOINT, EXPIRE_SOURCE_MS } from "./CONFIG";
import { isGA3, isGA4, getUTMParams, } from "./modules/ga/ga";
import toHash from "./modules/hash/hash";
import hyperstorage from "./modules/hyperstorage/hyperstorage";
import { ReferrerToSource } from "./modules/referrer-to-source/referrer-to-source";
import Cookies from 'js-cookie';
class Hit {
}
class FaCookieManager {
}
FaCookieManager.cookieName = 'farmalytics';
FaCookieManager.proxyFarmalyticsCookieName = '!Proxy!apiProxyfarmalyticsClientId';
FaCookieManager.farmalyticsCookieName = 'farmalyticsClientId';
FaCookieManager.save = (hit) => {
    Cookies.set(FaCookieManager.cookieName, JSON.stringify(hit));
};
FaCookieManager.saveCookies = (hit) => {
    if (hit.clientId !== null && hit.clientId !== undefined) {
        Cookies.set(FaCookieManager.farmalyticsCookieName, hit.clientId, {
            domain: '.infarmbureau.com',
            secure: true
        });
        Cookies.set(FaCookieManager.proxyFarmalyticsCookieName, hit.clientId, {
            domain: '.infarmbureau.com',
            secure: true
        });
    }
};
FaCookieManager.get = () => {
    const value = Cookies.get(FaCookieManager.cookieName);
    if (value) {
        var parsed = JSON.parse(value);
        if (parsed) {
            return parsed;
        }
    }
    return null;
};
/**
 * Generates a unique ID from a Source
 * @param source
 * @returns
 */
const getSourceHash = (source) => {
    return toHash(`${source.campaign}${source.medium}${source.source}`);
};
/**
 * Farmalytics Initializer
 * Setups Farmatics
 */
const FarmalyticsInitializer = () => {
    /**
     * Farmalytics State
     * Holds sources, property info and client id
     */
    const state = {
        sources: [],
        propertyId: null,
        clientId: null,
        shouldTrack: false,
    };
    /**
     * Retrieve GA4 Client ID from local storage or cookies
     * @returns {string | null} The retrieved Client ID or null if not found
     */
    const retrieveGA4ClientId = () => {
        let clientId = null;
        // Try to get the client ID from local storage
        const gaLocalStorage = Object.keys(localStorage).find(key => key.includes('_ga'));
        if (gaLocalStorage) {
            const storedValue = localStorage.getItem(gaLocalStorage);
            if (storedValue) {
                const parts = storedValue.split('.');
                if (parts.length >= 2) {
                    clientId = parts.slice(-2).join(".");
                }
            }
        }
        // If not found in local storage, try cookies
        if (!clientId) {
            const gaCookie = document.cookie.split('; ').find(row => row.startsWith('_ga'));
            if (gaCookie) {
                const cookieValue = gaCookie.split('=')[1];
                if (cookieValue) {
                    const parts = cookieValue.split('.');
                    if (parts.length >= 2) {
                        clientId = parts.slice(-2).join(".");
                    }
                }
            }
        }
        // Ensure clientId is not '0.0' and return
        return clientId && clientId !== '0.0' ? clientId : null;
    };
    /**
     * Initialize Farmalytics
     * @param config
     * @returns Promise void
     */
    const init = async (config) => {
        state.propertyId = config.propertyId;
        state.sources = (hyperstorage.getItem("sources") || []).filter((source) => {
            const expiresOn = new Date(source.created).getTime() + EXPIRE_SOURCE_MS;
            return new Date().getTime() < expiresOn;
        });
        /**
         * Handle Active Srouce
         * If a source is detected (any utm_* param)
         * it will be saved to the users hyperstorage
         */
        let activeSource = getUTMParams();
        /**
         * If we have no sources for this user, lets check the referrer
         * For any knowns sources
         */
        if (state.sources.length === 0 && !activeSource) {
            activeSource = ReferrerToSource();
        }
        if (activeSource) {
            // We have an active Source
            if (config.debug)
                console.log(`🔥 Active Source`, activeSource);
            // Does this source hash already exist?
            const activeHash = getSourceHash(activeSource);
            const exists = state.sources.find((s) => getSourceHash(s) === activeHash);
            // If a source doesn't exxist we'll add it
            if (!exists) {
                // This is a new Source for this user
                state.sources.unshift(activeSource); // Add source to first position
                state.sources = state.sources.filter((s, index) => index < 6);
                // Save to hyperstorage
                hyperstorage.setItem("sources", state.sources);
                // Track when we're loaded since it's a new
                state.shouldTrack = true;
                if (config.debug)
                    console.log("📁 Saved new source", activeSource);
            }
        }
        // Return a promise since GA might not be fully loaded yet.
        return new Promise(async (resolve) => {
            // Wait till we can get Client ID from Google Analytics
            state.clientId = retrieveGA4ClientId() || "Blocked";
            // Log out if logging
            if (config.debug) {
                console.log(`🐐 Property: ${state.propertyId}, Client: ${state.clientId}, GA: ${isGA3() ? "V3" : isGA4() ? "V4" : "⚠️ Unknown"}`);
            }
            // Return promise
            if (state.shouldTrack) {
                setTimeout(() => {
                    track()
                        .then((res) => {
                        if (config.debug)
                            console.log(res);
                    })
                        .catch((e) => {
                        console.error(e);
                    });
                    state.shouldTrack = false;
                }, 500);
            }
            resolve();
        });
    };
    /**
     * Get Client ID
     * This function will be publicly exposed and will return the GA4 client ID
     * @returns {string | null} The retrieved Client ID or null if not found
     */
    const getClientId = () => {
        return retrieveGA4ClientId();
    };
    const getPropertyId = () => {
        return state.propertyId;
    };
    /**
     * Get Sources
     * Returns an Array of the users last 5 sources
     * @returns Array Sources
     */
    const getSources = () => {
        return state.sources;
    };
    /**
     * Get Source
     * Returns the users last known source
     * @returns UTM Source
     */
    const getSource = () => {
        return state.sources.length ? state.sources[0] : undefined;
    };
    /**
     * Get the URL for the API endpoint to hit
     * @returns URL one level above executing script
     */
    const getUrl = () => {
        try {
            if ((document === null || document === void 0 ? void 0 : document.currentScript) && typeof document.currentScript !== 'undefined') {
                const url = new URL(`${document.currentScript.src}/..`);
                return url.href;
            }
            return API_ENDPOINT;
        }
        catch (e) {
            return API_ENDPOINT;
        }
    };
    const originUrl = getUrl();
    /**
     * Track Source to API
     */
    const track = async () => {
        const source = getSource();
        const payload = {
            clientId: state.clientId,
            referrer: document.referrer,
            propertyId: state.propertyId,
            source: (source || {}).source || "na",
            campaign: (source || {}).campaign || "na",
            medium: (source || {}).medium || "na",
            host: window.location.origin,
        };
        FaCookieManager.save(payload);
        const push = await fetch((Farmalytics === null || Farmalytics === void 0 ? void 0 : Farmalytics.originUrl) || API_ENDPOINT, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
            mode: 'cors'
        });
        const resp = await push.json();
        FaCookieManager.saveCookies(resp);
        return resp;
    };
    const getHistory = () => FaCookieManager.get();
    // Return functions
    return {
        init,
        getClientId,
        getPropertyId,
        getSources,
        getSource,
        track,
        originUrl,
        getHistory
    };
};
const Farmalytics = FarmalyticsInitializer();
if (window)
    window["Farmalytics"] = Farmalytics;
export default Farmalytics;
